import axios from "axios"
import {env} from "../variables/constants/Env";

const host      = "https://" + env("REACT_APP_API_HOST");
const api_key   = env("REACT_APP_API_KEY");

export const Api = {
    getPark,
    lightPark
};


async function getPark(parkId, payload) {
    const url = host + "/get_park/"+parkId+"/";
    const options = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    }
    const res = await axios.post(
        url, payload, options
    );

    return res;
}


async function lightPark(parkId, payload) {
    const url = host + "/turn_on/"+parkId+"/";
    const options = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': api_key
        }
    }
    const res = await axios.post(
        url, payload, options
    );

    return res;
}

