import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { env } from "./variables/constants/Env";

import Home from "./pages/home/Home";
import Park from "./pages/park/Park";

import './css/index.css';


function App() {
  const router_basename = env("REACT_APP_ROUTER_BASENAME");
  return (
    <Router basename={router_basename}>
      <div className="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/park/:pid" element={<Park />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;