import { React, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from "reactstrap"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Api } from '../../sdk/park-light-service.js';

import tondoLogo from '../../images/Tondo-Logo-white-large-2.webp';
import cityLogo from '../../images/rishon_logo.webp';

const Park = () => {
    const debugMode = false;
    const params = useParams();
    const [loadDataDelay] = useState(5);
    const [serviceEnabled, setServiceEnabled] = useState(false);
    const [timeRemainingDisplay, setTimeRemainingDisplay] = useState(0);
    const [remainingTimeMinutes, setRemainingTimeMinutes] = useState(0);
    const [lightTimeMinutes, setLightTimeMinutes] = useState(0);
    const [remainingTime, setRemainingTime] = useState("אור כבוי");
    const [disabled, setDisabled] = useState(true);
    const [location, setLocation] = useState(null);
    const [loadingMode, setLoadingMode] = useState(false);
    const [countLoadingMode, setCountLoadingMode] = useState(0);

    const [workingTimes, setWorkingTimes] = useState("");
    const [parkName, setParkName] = useState("");
    const [systemMessage, setSystemMessage] = useState("");
    const [getParkDataFlag, setGetParkDataFlag] = useState(0);

    const [parkData, setParkData] = useState(null);
    const parkId = params.pid;

    const turnOnLight = () => {
        try{
            navigator.vibrate(50);
        }catch(e){
            console.log("Not supported on IOS");
        }
        console.log("Turning light on for park: "+parkId);
        setRemainingTime("מדליק אור");
        setDisabled(true);
        setLoadingMode(true);
        Api.lightPark(parkId, location).then(res => {
            setTimeout(() => {
                setGetParkDataFlag(getParkDataFlag+1);
                setLoadingMode(false);
            }, 1000*loadDataDelay);
        });
    }

    const translate = (text) => {
        const t = {
            "Outside of the allowed radius": "לא ניתן להפעיל ממיקום זה, עליך להיות במגרש",
            "Out of work hours": "לא ניתן להפעיל בשעות אלו"
        }
        return t[text];
    }

    useEffect(() => {
        if(!loadingMode){
            return;
        }
        if(countLoadingMode >= 100){
            setCountLoadingMode(100);
            setTimeout(() => {
                setGetParkDataFlag(0);
            }, 1000*2);
        }
        const interval = setInterval(() => {
            setCountLoadingMode(countLoadingMode+1);
            setTimeRemainingDisplay(countLoadingMode+1);
        }, 1000/(10/(loadDataDelay/10)));
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingMode, countLoadingMode]);
    

    const convertToLocaltime = (utcTime) => {

        const theTimeArray = utcTime.split(":");
        let theTimeHours = parseInt(theTimeArray[0]);
        let theTimeMinutes = parseInt(theTimeArray[1]);

        theTimeHours+=2;
        if(theTimeHours>=24){
            theTimeHours = theTimeHours - 24;
        }

        return theTimeHours.toString().padStart(2, '0')+":"+theTimeMinutes.toString().padStart(2, '0');
    }

    useEffect(() => {
        if(!parkData){
            return;
        }
        setLightTimeMinutes(parkData.data.service_config.light_time_minutes);
        setRemainingTimeMinutes(parkData.data.remaining_time>=0?parkData.data.remaining_time:0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parkData]);

    useEffect(() => {
        if(remainingTimeMinutes < 0){
            return;
        }
        const interval = setInterval(() => {
            const remainTimeMin = remainingTimeMinutes - 0.01666666666;
            const percentage = (remainingTimeMinutes / lightTimeMinutes) * 100;
            setRemainingTimeMinutes(remainTimeMin);
            setTimeRemainingDisplay(percentage);
        }, 1000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remainingTimeMinutes]);

    useEffect(() => {
        if(getParkDataFlag > 20){
            return;
        }
        const interval = setInterval(() => {
            console.log("interval: "+getParkDataFlag);
            setGetParkDataFlag(getParkDataFlag+1);          
        }, 1000*60);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getParkDataFlag]);

    useEffect(() => {
        if(debugMode){
            setLocation({"lat": "32.03792641018304","lon": "34.85357401292802"});
            //setLocation({"lat": "0.03792641018304","lon": "0.85357401292802"});
        }else{
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                (position) => {
                    // enable in production
                    if(!debugMode){
                        setLocation({"lat": position.coords.latitude, "lon": position.coords.longitude});
                        console.log("your location:", {"lat": position.coords.latitude, "lon": position.coords.longitude});
                    }
                },
                (error) => {
                    setSystemMessage("השירות אינו פעיל במיקום הזה");
                    setParkName("מיקום לא נמצא");
                    console.error(error);
                }
                );
            } else {
                setSystemMessage("השירות אינו פעיל במיקום הזה");
                setParkName("מיקום לא נמצא");
                console.error('Geolocation is not supported by this browser.');
            }
        }
    }, [debugMode]);

    useEffect(() => {
        if(parkData){
            //return;
        }
        
        console.log("Getting park Data");
        console.log(location);
        if(location){
            console.log("Requesting park Data");
            Api.getPark(parkId, location).then(parkData => {
                console.log("Park Data");
                console.log(parkData);
                const jsonMeta = parkData.data.meta_data;
                try{
                    setParkName(jsonMeta.name);
                }catch(e){
                    setParkName("פארק");
                }

                if(!parkData.data.on_button_to_enable || !parkData.data.service_config.enabled){
                    setDisabled(true);
                    console.log("Set Disable");
                    if(parkData.data.power === 0){
                        setSystemMessage("שירות אינו פעיל בשעות אלו");
                    }
                }else{
                    setDisabled(false);
                    console.log("Set Enable");
                }

                if(parkData.data.reason){
                    console.log(translate(parkData.data.reason));
                    setSystemMessage(translate(parkData.data.reason));
                }
                
                //TODO:                //  Add case for dusk
                const remainingTimeMinutesLocal = parkData.data.remaining_time>=0?parkData.data.remaining_time:0;
                const timeToCloseLightsSeconds = parkData.data.last_start_time*100000+parkData.data.service_config.light_time_minutes;
                const timeToCloseLights = new Date(parseInt(timeToCloseLightsSeconds));
                const closeHour = timeToCloseLights.getHours();
                const closeMinute = timeToCloseLights.getMinutes();
                const endTime = parkData.data.service_config.work_hours.end;
                const startTime = parkData.data.service_config.work_hours.start;
                const timeRemainingHour   = parseInt(remainingTimeMinutesLocal/60);
                const timeRemainingMinute = parseInt(remainingTimeMinutesLocal - timeRemainingHour*60);
                
                setServiceEnabled(parkData.data.service_config.enabled);
                setWorkingTimes(convertToLocaltime(endTime.time)+" - "+convertToLocaltime(startTime.time));

                const endtimeArray = endTime.time.split(":");
                let endHour = endtimeArray[0];
                let endMinute = parseInt(endtimeArray[1]);


                if(endHour === "00"){
                    endHour = 24;
                }

                const absEndSchedule = endHour*60+endMinute;
                const absEndTimeLight = closeHour*60+closeMinute;

                if(absEndTimeLight < absEndSchedule){
                    //  Use Light limit time
                    endHour = closeHour;
                    endMinute = closeMinute;
                }else{
                    //  Use schedule end time

                }

                if(!parkData.data.service_config.enabled){
                    setSystemMessage("השירות אינו פעיל כרגע");
                }

                //if(!parkData.data.service_config.enabled || parkData.data.power === 0 || remainingTimeMinutesLocal <= 0 ){
                if((parkData.data.request_submitted || parkData.data.power > 0) && remainingTimeMinutesLocal > 0){
                    //TODO: put here the percentage of time remaining
                    const percentage = (remainingTimeMinutesLocal / parkData.data.service_config.light_time_minutes) * 100;
                    setTimeRemainingDisplay(percentage);
                    setRemainingTime(timeRemainingHour.toString().padStart(2, '0') + ":" + timeRemainingMinute.toString().padStart(2, '0'));
                }else{
                    setRemainingTime("אור כבוי");
                    setTimeRemainingDisplay(0);
                }
                setParkData(parkData);
            });
        }else{
            setDisabled(true);
            setServiceEnabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.pid, location, getParkDataFlag]);

    return (
        <div className="app">
            
            <div className="header">
                <Container>
                    <Row>
                        <Col>
                            <div className="tondoLogo"><a href="https://tondo-iot.com" rel="noreferrer" target="_blank"><img src={tondoLogo} alt="Tondo Smart" /></a></div>
                        </Col>
                        <Col>
                            <div className="cityLogo"><a href="https://www.rishonlezion.muni.il" rel="noreferrer" target="_blank"><img src={cityLogo} alt="ראשון לציון" /></a></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col xs="9">
                            <span className="Title">
                                {parkName}
                            </span>
                        </Col>
                        <Col xs="3" className="align-left">
                        </Col>
                    </Row>
                    { (!serviceEnabled || systemMessage)&& 
                                <Row>
                                    <Col className="alert">{systemMessage}</Col>    
                                </Row>
                    }
                    { serviceEnabled &&
                    <Row>
                        <Col xs="12">
                            <span className="working-hours">
                                שעות הפעילות: {workingTimes}                                
                            </span>
                        </Col>
                    </Row>
                    }
                    {(true || disabled) &&
                    <Row>
                        <Col xs="12" className="center">
                            <CircularProgressbar styles={buildStyles({strokeLinecap: 'butt', pathColor: '#ffd43d', trailColor: '#ffffff'})} className="time-remaining" value={timeRemainingDisplay} text={`${remainingTime}`} />
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col xs="12" className="center">
                            <Button disabled={disabled} id="light-button" className="light-button" onClick={turnOnLight}>הדלק אור</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="center">
                            <a href="https://tondo-iot.com/privacy-statement-ca/?cmplz_region_redirect=true&cmplz-region=eu" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </Col>
                    </Row>                    
                </Container>
            </div>
        </div>
    );
}

export default Park;