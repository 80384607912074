import { React } from "react";
import { Container, Row, Col } from "reactstrap"
import 'react-circular-progressbar/dist/styles.css';

import tondoLogo from '../../images/Tondo-Logo-white-large-2.webp';
import cityLogo from '../../images/rishon_logo.webp';


const Home = () => {

    return (
        <div className="app">
            <div className="header">
                <Container>
                    <Row>
                        <Col>
                            <div className="tondoLogo"><img src={tondoLogo} alt="Tondo Smart" /></div>
                        </Col>
                        <Col>
                            <div className="cityLogo"><img src={cityLogo} alt="Tondo Smart" /></div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col xs="9">
                            <span className="Title">
                                אופרציית מגרשים
                            </span>
                        </Col>
                        <Col className="align-left">
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Home;