const ENV = {};

function _initConstants() {
    Object.keys(process.env).forEach(function(key) {
        ENV[key] = process.env[key];
    });
}

export function env(envKey){
    if(Object.keys(ENV).length<1){
        _initConstants();
    }
    return ENV[envKey];
}